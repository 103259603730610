import React from 'react'
import PropTypes from 'prop-types'

function Wrench({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <path d="M123.34 1106.2c22.535 22.547 52.094 33.816 81.637 33.816s59.09-11.27 81.637-33.816l478.3-478.3a291.87 291.87 0 0085.02 12.648c74.281 0 148.54-28.297 205.21-84.973 86.891-86.891 106.96-215.07 60.684-321.31l-117.6 117.6c-13.727 13.727-31.715 20.594-49.703 20.594s-35.977-6.863-49.703-20.594l-50.664-50.652c-27.457-27.457-27.457-71.953 0-99.406l117.6-117.6c-36.996-16.117-76.684-24.191-116.33-24.191-74.207 0-148.36 28.234-204.98 84.875-78.551 78.551-102.6 190.88-72.324 290.22l-478.3 478.3c-45.086 45.086-45.086 118.19 0 163.27z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Wrench.propTypes = {
  styling: string,
}

export default Wrench
