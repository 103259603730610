import React from 'react'
import PropTypes from 'prop-types'

function Qrcode({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>QR Code</title>
      <path d="M516.77-.082c-.047.023-.098.047-.145.074a37.561 37.561 0 00-30.816 20.63 37.56 37.56 0 0034.7 54.37h116.53v375.36a37.506 37.506 0 0064.312 27.062 37.526 37.526 0 0010.687-27.062V37.342a37.5 37.5 0 00-37.355-37.35h-154.18a36.086 36.086 0 00-3.734-.075zM39.09-.008a37.492 37.492 0 00-26.371 10.984A37.498 37.498 0 001.735 37.343v366.5a37.488 37.488 0 0010.883 26.56 37.523 37.523 0 0026.473 11.09h331.42a37.523 37.523 0 0026.473-11.09 37.487 37.487 0 0010.883-26.56v-366.5a37.5 37.5 0 00-37.355-37.35zm793.95 0a37.492 37.492 0 00-26.371 10.984 37.498 37.498 0 00-10.984 26.367v366.5a37.488 37.488 0 0010.883 26.56 37.523 37.523 0 0026.473 11.09h331.05a37.5 37.5 0 0037.644-37.648v-366.5a37.503 37.503 0 00-11.086-26.474A37.507 37.507 0 001164.09-.008zM35.8 557.732a37.533 37.533 0 00-32.508 18.766A37.539 37.539 0 0035.8 632.807h453.73v529.54a37.488 37.488 0 0010.883 26.56 37.523 37.523 0 0026.473 11.09h174.1-.004a37.519 37.519 0 0027.062-10.689 37.503 37.503 0 0011.281-26.812 37.503 37.503 0 00-38.343-37.5h-136.45v-529.62a37.484 37.484 0 00-10.984-26.66 37.484 37.484 0 00-26.66-10.984zm648.34 9.742a37.477 37.477 0 00-24.082 12.168 37.497 37.497 0 00-9.684 25.184v247.19a37.531 37.531 0 0010.684 27.062 37.512 37.512 0 0053.632 0 37.523 37.523 0 0010.684-27.062v-209.54h438.72a37.513 37.513 0 0031.789-56.015 37.51 37.51 0 00-31.789-18.984h-476.37a36.677 36.677 0 00-3.59 0zm148.9 190.43a37.503 37.503 0 00-37.356 37.645v366.21a37.52 37.52 0 0010.684 27.06 37.523 37.523 0 0026.816 11.284 37.514 37.514 0 0037.5-38.344V832.9h256.05v328.86a37.516 37.516 0 0037.5 38.344 37.515 37.515 0 0037.5-38.344V795.55a37.484 37.484 0 00-10.984-26.66 37.484 37.484 0 00-26.66-10.984zm-793.95.512a37.503 37.503 0 00-37.356 37.645v366.29a37.488 37.488 0 0010.883 26.56A37.523 37.523 0 0039.09 1200h331.42a37.523 37.523 0 0026.473-11.09 37.487 37.487 0 0010.883-26.56v-366.29a37.502 37.502 0 00-37.356-37.645z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Qrcode.propTypes = {
  styling: string,
}

export default Qrcode
