import React from 'react'
import PropTypes from 'prop-types'

function Forest({ styling }) {
  return (
    <svg
      className={styling}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1200 1200"
    >
      <path d="M1149.6 1004.4c-56.398-25.199-133.2-46.801-223.2-62.398l-.004-122.4h182.4c18 0 28.801-21.602 16.801-36l-114-134.4h64.801c18 0 28.801-20.398 16.801-36L972 471.602h36c18 0 27.602-20.398 16.801-36L900 289.202c-8.398-9.602-25.199-9.602-32.398 0l-73.199 85.199 61.2 73.199c18 20.398 21.601 50.398 9.6 74.398-7.199 15.602-19.198 27.602-33.601 33.602l57.602 68.398c18 20.398 21.602 49.199 9.601 74.398-10.8 25.199-36 40.801-63.602 40.801l-141.6.004v80.398l146.4.004v110.4c-61.199-7.2-126-10.801-194.4-12v-225.6h190.8c19.2 0 30-22.801 16.801-37.2l-118.8-140.4h67.2c19.198 0 30-22.8 16.8-37.198l-126-148.8h37.2c19.198 0 30-22.801 16.8-37.2l-129.6-153.6c-8.398-10.8-25.199-10.8-33.602 0l-130.8 153.6c-12 14.399-2.398 37.2 16.801 37.2h37.2l-126 148.8c-12 14.398-2.4 37.199 16.8 37.199h67.2l-118.8 140.4c-12 14.398-2.4 37.199 16.8 37.199h190.8v225.6c-68.398 1.199-133.2 6-194.4 12v-110.4h146.4v-80.398l-141.6-.004c-27.602 0-51.602-15.602-63.602-40.801-12-25.2-7.199-54 9.602-74.398l58.8-68.398c-14.397-7.2-26.397-19.2-33.601-33.602-10.801-25.2-7.2-54 9.601-74.398l61.2-73.2-73.2-85.198c-8.398-9.602-24-9.602-32.398 0l-124.8 146.4c-12 14.398-2.398 36 16.801 36h36l-121.2 142.8c-12 14.398-2.398 36 16.801 36h64.801l-115.2 134.4c-12 14.398-2.398 36 16.801 36h182.4v122.4c-90 15.602-165.6 36-223.2 62.398-34.8 15.602-21.602 68.398 16.801 64.8 157.2-14.397 338.4-22.8 532.8-22.8 194.4 0 376.8 8.398 532.8 22.8 39.598 2.403 52.797-50.397 18-66z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Forest.propTypes = {
  styling: string,
}

export default Forest
