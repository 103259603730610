import React from 'react'
import PropTypes from 'prop-types'

function Save({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Save</title>
      <path d="M1125 368.57v643.93a112.603 112.603 0 01-32.988 79.512A112.597 112.597 0 011012.5 1125h-75V750a112.507 112.507 0 00-32.949-79.551A112.503 112.503 0 00825 637.5H375a112.507 112.507 0 00-79.551 32.949A112.503 112.503 0 00262.5 750v375h-75a112.603 112.603 0 01-79.512-32.988A112.597 112.597 0 0175 1012.5v-825a112.603 112.603 0 0132.988-79.512A112.597 112.597 0 01187.5 75H375v187.5a112.507 112.507 0 0032.949 79.551A112.503 112.503 0 00487.5 375h225a112.507 112.507 0 0079.551-32.949A112.503 112.503 0 00825 262.5V75h6.426a111.77 111.77 0 0179.562 32.957l181.05 181.05a111.771 111.771 0 0132.957 79.562zM450 75v187.5a37.49 37.49 0 0010.984 26.516A37.485 37.485 0 00487.5 300h225a37.49 37.49 0 0026.516-10.984A37.485 37.485 0 00750 262.5V75zm412.5 675v375h-525V750a37.49 37.49 0 0110.984-26.516A37.485 37.485 0 01375 712.5h450a37.49 37.49 0 0126.516 10.984A37.485 37.485 0 01862.5 750zm-75 187.5h-375a37.498 37.498 0 100 75h375a37.498 37.498 0 100-75zm0-112.5h-375a37.498 37.498 0 100 75h375a37.498 37.498 0 100-75z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Save.propTypes = {
  styling: string,
}

export default Save
