import React from 'react'
import PropTypes from 'prop-types'
/**
 * @summary Walking
 * @component
 *
 * @param {object} props
 *
 * @returns {node} jsx markup
 */
function Walking({ styling }) {
  return (
    <svg
      className={styling}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 100 100"
      stroke="currentColor"
    >
      <title>Walking</title>
      <path d="M42.034 40.411c-1.893 1.448-3.826 2.848-5.651 4.376-.45.377-.672 1.151-.788 1.782-.662 3.616-1.253 7.245-1.883 10.867-.395 2.273-1.576 3.241-3.462 2.875-1.648-.32-2.405-1.767-2.077-3.869.694-4.448 1.293-8.914 2.097-13.342.197-1.083.809-2.296 1.614-3.026 3.117-2.827 6.431-5.436 9.593-8.216 3.01-2.647 6.543-2.606 9.98-1.656 1.318.364 2.548 2.035 3.262 3.401 1.909 3.653 3.462 7.492 5.286 11.192.424.861 1.243 1.697 2.091 2.149 3.046 1.621 6.203 3.033 9.27 4.616.83.428 1.761 1.048 2.15 1.828.372.746.407 1.984.017 2.688-.328.593-1.468.962-2.271 1.003-.847.043-1.75-.346-2.575-.677-1.462-.587-2.888-1.265-4.322-1.922-4.002-1.832-8.379-3.16-10.199-7.945l-.614.162c-.755 3.446-1.559 6.882-2.21 10.347-.099.529.46 1.225.805 1.789 4.897 8.013 9.827 16.006 14.7 24.033.69 1.137 1.319 2.385 1.622 3.665.385 1.624.001 3.133-1.711 4.001-1.671.847-3.273.778-4.544-.589-1.322-1.422-2.561-2.957-3.622-4.582-5.587-8.556-11.072-17.179-16.672-25.726-1.801-2.75-2.363-5.578-1.595-8.776.803-3.34 1.434-6.72 2.14-10.083l-.431-.365z" />
      <path d="M38.695 62.104c1.846 2.538 3.515 4.757 5.082 7.046.322.47.509 1.282.338 1.797-.635 1.915-1.126 3.994-2.252 5.606-3.09 4.426-6.383 8.721-9.781 12.918-1.592 1.967-3.439 2.123-4.925.968-1.43-1.111-1.638-3.008-.351-5.08 1.306-2.104 2.634-4.223 4.204-6.128 3.57-4.331 5.666-9.299 6.872-14.71.114-.511.283-1.01.438-1.51.06-.197.156-.383.375-.907zM54.108 26.537c-4.28-.011-7.572-3.25-7.6-7.479-.029-4.332 3.336-7.713 7.66-7.697 4.326.016 7.659 3.416 7.602 7.754-.058 4.229-3.365 7.433-7.662 7.422z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Walking.propTypes = {
  styling: string,
}

export default Walking
