import React from 'react'
import PropTypes from 'prop-types'

function Link({ styling }) {
  return (
    <svg
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
      className={styling}
    >
      <title>Link</title>
      <path d="M655.97 93.371l-210.5 210.46c62.688-17.449 129.7-18.625 193.3-1.633 8.617 2.352 16.895 5.594 25.32 8.496l104.59-104.59c62.16-62.28 162.98-62.062 225.31 0 62.16 62.45 62.113 162.98-.047 225.27l-142.22 142.27-83.039 82.992c-20.496 20.496-45.336 33.602-71.52 40.586-53.113 14.328-111.96 1.274-153.72-40.512-28.176-28.105-42.984-64.055-45.504-100.75-12.312 5.375-23.64 12.023-32.855 21.191l-98.594 98.594c15.406 33.863 36.336 65.832 64.246 93.672 41.016 41.09 90.602 68.305 142.97 82.246 81.602 21.742 170.02 10.512 244.44-33.648 22.512-13.32 43.895-29.352 63.238-48.672l225.31-225.31c124.59-124.51 124.32-326.26.168-450.62-124.73-124.39-326.43-124.56-450.89-.023z" />
      <path d="M753.7 896.51c-71.473 19.754-147.57 17.305-217.89-6.938l-104.35 104.43c-62.281 62.04-163.11 62.04-225.36-.07-62.184-62.375-62.062-162.98-.07-225.36l225.41-225.36c20.566-20.426 45.289-33.48 71.375-40.44 53.113-14.23 111.96-1.177 153.89 40.511 28.176 28.391 42.984 64.56 45.383 101.47 11.785-5.375 22.969-12.504 32.64-22.199l98.712-98.64c-15.312-33.77-36.191-65.618-63.938-93.435-41.16-41.016-90.695-68.258-143.02-82.199-81.695-21.793-170.02-10.512-244.51 33.602-22.488 13.273-43.848 29.258-63.238 48.574l-225.36 225.36c-124.46 124.54-124.54 326.35-.07 450.77 124.51 124.49 326.21 124.49 450.79 0z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Link.propTypes = {
  styling: string,
}

export default Link
