import React from 'react'
import PropTypes from 'prop-types'

function Dashboard({ styling }) {
  return (
    <svg
      className={styling}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
    >
      <path d="M495.84 272.64v297.6-.004a71.999 71.999 0 01-72 72h-151.2a71.996 71.996 0 01-72-72v-297.6a72.012 72.012 0 0121.086-50.914 72.012 72.012 0 0150.914-21.086h151.2a71.996 71.996 0 0172 72zm-72 424.32h-151.2a72.012 72.012 0 00-50.914 21.086 72.012 72.012 0 00-21.086 50.914v158.4a72.012 72.012 0 0021.086 50.914 72.012 72.012 0 0050.914 21.086h151.2a71.996 71.996 0 0072-72v-158.4a71.999 71.999 0 00-72-72zm503.52-177.12h-304.8a71.997 71.997 0 00-50.91 21.09 71.99 71.99 0 00-21.09 50.91v335.52a71.999 71.999 0 0072 72h304.8a72.012 72.012 0 0050.914-21.086 72.012 72.012 0 0021.086-50.914V591.84a72.003 72.003 0 00-72-72zm0-319.2h-304.8a72 72 0 00-72 72v120.48a71.997 71.997 0 0021.09 50.91 71.997 71.997 0 0050.91 21.09h304.8a71.999 71.999 0 0072-72V272.64a72.012 72.012 0 00-21.086-50.914 72.012 72.012 0 00-50.914-21.086z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Dashboard.propTypes = {
  styling: string,
}

export default Dashboard
