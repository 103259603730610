import React from 'react'
import PropTypes from 'prop-types'

function QuestionCircleSolid({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Help</title>
      <path d="M600 36a564.016 564.016 0 00-398.81 165.19C95.42 306.96 36 450.42 36 600s59.422 293.04 165.19 398.81C306.96 1104.58 450.42 1164 600 1164s293.04-59.422 398.81-165.19C1104.58 893.04 1164 749.58 1164 600s-59.422-293.04-165.19-398.81C893.04 95.42 749.58 36 600 36zm-7.68 935.04a64.559 64.559 0 01-64.558-64.558c0-17.121 6.801-33.543 18.906-45.652a64.574 64.574 0 0191.304 0 64.575 64.575 0 0118.906 45.652 64.692 64.692 0 01-18.94 45.617 64.69 64.69 0 01-45.618 18.941zm70.56-313.44s-6.36 2.281-6.36 6v51.242a64.262 64.262 0 01-32.13 55.652 64.273 64.273 0 01-64.261 0 64.262 64.262 0 01-32.13-55.652v-51.238a135.804 135.804 0 0126.294-78.984 135.797 135.797 0 0167.426-48.816A91.2 91.2 0 00684 446.284a93.732 93.732 0 00-28.605-62.621 93.729 93.729 0 00-63.676-26.18 90.978 90.978 0 00-48.324 14.148A90.97 90.97 0 00510 409.322a64.277 64.277 0 01-53.113 36.191 64.262 64.262 0 01-57.898-27.898 64.272 64.272 0 01-4.79-64.094 218.779 218.779 0 0180.294-90.551 218.75 218.75 0 01116.15-34.008h1.32a221.759 221.759 0 01220.32 213.476A219.02 219.02 0 01663.004 657.48z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
QuestionCircleSolid.propTypes = {
  styling: string,
}

export default QuestionCircleSolid
