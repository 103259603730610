import React from 'react'
import PropTypes from 'prop-types'

function Images({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Images</title>
      <g>
        <path d="M972 743.28v120a23.987 23.987 0 007.031 16.969 24.002 24.002 0 0016.97 7.031h38.878a57.13 57.13 0 0040.391-16.73 57.132 57.132 0 0016.73-40.39V177.84a57.136 57.136 0 00-16.73-40.392 57.125 57.125 0 00-40.39-16.73l-677.76 1.2.003.003a57.123 57.123 0 00-40.39 16.727 57.132 57.132 0 00-16.73 40.391v39a23.997 23.997 0 0024 24h120l468-.84a59.988 59.988 0 0142.425 17.575 59.993 59.993 0 0117.574 42.426z" />
        <path d="M108 371.04v651.12a57.136 57.136 0 0016.73 40.391 57.125 57.125 0 0040.391 16.73h677.76a57.13 57.13 0 0040.391-16.73 57.132 57.132 0 0016.73-40.391V369.84a57.136 57.136 0 00-16.73-40.391 57.125 57.125 0 00-40.391-16.73l-677.76 1.2.004.003a57.123 57.123 0 00-40.391 16.727 57.132 57.132 0 00-16.73 40.391zm120 70.078v.004c0-4.172 1.656-8.168 4.606-11.117a15.708 15.708 0 0111.113-4.606h520.56c4.167 0 8.167 1.657 11.112 4.606a15.717 15.717 0 014.606 11.117v282.72a15.71 15.71 0 01-4.316 10.66c-2.735 2.879-6.454 4.617-10.418 4.867s-7.875-1.012-10.945-3.527l-132-121.08a15.721 15.721 0 00-10.082-3.66c-3.684 0-7.25 1.297-10.078 3.66l-163.32 169.2a15.867 15.867 0 01-10.844 3.726 15.846 15.846 0 01-10.516-4.566l-86.281-54.84a15.837 15.837 0 00-22.32 0l-54 38.16a15.712 15.712 0 01-22.3.016 15.727 15.727 0 01-4.579-11.176z" />
      </g>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Images.propTypes = {
  styling: string,
}

export default Images
