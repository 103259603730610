import React from 'react'
import PropTypes from 'prop-types'

function Quotation({ styling }) {
  return (
    <svg
      className={styling}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <switch>
        <g>
          <path d="M5273.1 2400.1v-2c0-2.8-5-4-9.7-4s-9.7 1.3-9.7 4v2c0 1.8.7 3.6 2 4.9l5 4.9c.3.3.4.6.4 1v6.4c0 .4.2.7.6.8l2.9.9c.5.1 1-.2 1-.8v-7.2c0-.4.2-.7.4-1l5.1-5c1.3-1.3 2-3.1 2-4.9zm-9.7-.1c-4.8 0-7.4-1.3-7.5-1.8.1-.5 2.7-1.8 7.5-1.8s7.3 1.3 7.5 1.8c-.2.5-2.7 1.8-7.5 1.8z" />
          <path d="M5268.4 2410.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1h-4.3zM5272.7 2413.7h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1zM5272.7 2417h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1 0-.5-.4-1-1-1z" />
          <g>
            <path d="M62 47.9c-5.7 0-10.2 4.6-10.2 10.2 0 5.3 4.1 9.7 9.3 10.1-1.1 1.9-3 4-6.3 6-.9.5-1.5 1.5-1.5 2.5 0 2.1 2.1 3.5 4 2.7 5.5-2.5 14.8-8.6 14.8-21.3.1-5.6-4.5-10.2-10.1-10.2zM87.3 47.9c-5.7 0-10.2 4.6-10.2 10.2 0 5.3 4.1 9.7 9.3 10.1-1.1 1.9-3 4-6.3 6-.9.5-1.5 1.5-1.5 2.5 0 2.1 2.1 3.5 4 2.7 5.5-2.5 14.8-8.6 14.8-21.3.1-5.6-4.5-10.2-10.1-10.2zM38.9 31.7c1.1-1.9 3-4 6.3-6 .9-.5 1.5-1.5 1.5-2.5 0-2.1-2.1-3.5-4-2.7-5.5 2.5-14.8 8.6-14.8 21.3 0 5.6 4.6 10.2 10.2 10.2 5.7 0 10.2-4.6 10.2-10.2-.1-5.3-4.2-9.6-9.4-10.1zM13.6 31.7c1.1-1.9 3-4 6.3-6 .9-.5 1.5-1.5 1.5-2.5 0-2.1-2.1-3.5-4-2.7C11.9 23 2.6 29.1 2.6 41.8 2.6 47.4 7.2 52 12.8 52 18.5 52 23 47.4 23 41.8c0-5.3-4.1-9.6-9.4-10.1z" />
          </g>
        </g>
      </switch>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Quotation.propTypes = {
  styling: string,
}

export default Quotation
