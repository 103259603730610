import React from 'react'
import SectionTitle from 'components/SectionTitle'

function AlphabetPlaceholder() {
  return (
    <section className="py-12 bg-white" data-testid="AlphabetPlaceholder">
      <div className="mx-10 mb-12">
        <SectionTitle.Presentation placeholder />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-7 gap-8 md:divide-x-2 divide-charcoal-50">
          <div className="col-span-7 md:col-span-4">
            <div className="grid grid-cols-7 md:grid-cols-5 lg:grid-cols-7">
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
              <span className="bg-charcoal-50 text-charcoal-50 col-span-1 font-medium inline-flex justify-center m-1 p-5 rounded text-2xl">
                &nbsp;
              </span>
            </div>
          </div>
          <div className="col-span-7 md:col-span-3 mt-8 md:mt-0">
            <div className="text-center font-bold sm:text-3xl text-2xl m-10 bg-charcoal-50 text-charcoal-50">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AlphabetPlaceholder
