import React from 'react'
import PropTypes from 'prop-types'
/**
 * @summary LogOut
 * @component
 *
 * @param {object} props
 *
 * @returns {node} jsx markup
 */
function LogOut({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <g>
        <path d="m218.35 643.75h716.32c9.9492 0 18.801-3.5117 25.637-10.164 0.30078-0.27344 0.625-0.60156 0.98828-0.96094l1.1484-1.1992c6.375-6.75 9.7266-15.426 9.7266-25.176v-12.5c0-20.676-16.824-37.5-37.5-37.5h-716.3l213.77-186.32c8.0117-6.9766 12.324-17.238 11.836-28.148-0.39844-9.0742-3.9609-17.898-10.012-24.824l-8.2109-9.4258c-6.6641-7.6367-16.062-11.852-26.449-11.852-9.5391 0-19.211 3.6641-26.523 10.039l-306.25 266.93c-7.5742 6.6016-11.938 16.574-11.938 27.375s4.3516 20.789 11.938 27.398l306.25 266.89c7.3242 6.3867 17 10.051 26.539 10.051 10.387 0 19.789-4.2109 26.426-11.863l8.2109-9.4258c6.0508-6.9375 9.6133-15.762 10.012-24.824 0.48828-10.914-3.8242-21.164-11.824-28.137z" />
        <path d="m1107.9 87.812h-345.16c-9.9141 0-18.738 3.4883-25.574 10.125-0.35156 0.32422-0.73828 0.69922-1.1641 1.125l-1.0859 1.1484c-6.25 6.6875-9.6758 15.551-9.6758 25.113v12.5c0 20.676 16.824 37.5 37.5 37.5h295.16v849.36h-295.16c-20.676 0-37.5 16.824-37.5 37.5v12.5c0 20.676 16.824 37.5 37.5 37.5h345.16c20.676 0 37.5-16.824 37.5-37.5l-0.003906-949.38c0-20.676-16.824-37.5-37.5-37.5z" />
      </g>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
LogOut.propTypes = {
  styling: string,
}

export default LogOut
