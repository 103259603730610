import React from 'react'
import PropTypes from 'prop-types'
/**
 * @summary Kids
 * @component
 *
 * @param {object} props
 *
 * @returns {node} jsx markup
 */
function Kids({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className={styling}
    >
      <path
        d="M68.437 60.078c-.04.123-1 3.049-3.83 5.92-2.574 2.614-7.268 5.73-15.007 5.73s-12.433-3.116-15.007-5.73c-2.83-2.871-3.79-5.797-3.83-5.92a1.961 1.961 0 013.734-1.196c.119.353 3.12 8.927 15.103 8.927 11.984 0 14.985-8.574 15.107-8.939a1.956 1.956 0 012.464-1.262 1.964 1.964 0 011.266 2.47zM37.784 39.453a5.145 5.145 0 015.141 5.137 5.145 5.145 0 01-5.14 5.136 5.144 5.144 0 01-5.141-5.136 5.144 5.144 0 015.14-5.137zm23.632 0a5.144 5.144 0 015.14 5.137 5.144 5.144 0 01-5.14 5.136 5.144 5.144 0 01-5.14-5.136 5.144 5.144 0 015.14-5.137zm35.683 8.306a9.503 9.503 0 002.423-9.808 9.513 9.513 0 00-7.73-6.511l-20.004-2.905a5.652 5.652 0 01-4.257-3.09L58.585 7.333A9.517 9.517 0 0050 2a9.517 9.517 0 00-8.587 5.333l-8.946 18.112a5.652 5.652 0 01-4.257 3.09L8.206 31.44a9.514 9.514 0 00-7.73 6.511 9.504 9.504 0 002.423 9.808l14.475 14.098a5.645 5.645 0 011.626 5l-3.417 19.907a9.505 9.505 0 003.809 9.358 9.547 9.547 0 005.62 1.837 9.587 9.587 0 004.464-1.11l17.892-9.398a5.657 5.657 0 015.262 0l17.892 9.399a9.522 9.522 0 0010.084-.728 9.504 9.504 0 003.809-9.358l-3.417-19.907a5.646 5.646 0 011.626-5l14.475-14.098z"
        fillRule="evenodd"
      />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Kids.propTypes = {
  styling: string,
}

export default Kids
