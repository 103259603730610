import React from 'react'
import PropTypes from 'prop-types'
/**
 * @summary BearPaw
 * @component
 *
 * @param {object} props
 *
 * @returns {node} jsx markup
 */
function BearPaw({ styling }) {
  return (
    <svg
      className={styling}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 100 100"
      stroke="currentColor"
    >
      <title>BearPaw</title>
      <path d="M50.522,88c2.232,0-3.799,4.332,12.844,5.175c16.643,0.842,16.307-23.582,10.053-27.793   c-6.255-4.211-2.345-5.534-6.479-11.43c-4.133-5.896-16.418-5.293-16.418-5.293s-12.307-0.603-16.446,5.293   c-4.14,5.896-0.224,7.219-6.488,11.43c-6.264,4.211-6.601,28.635,10.068,27.793C54.325,92.332,48.285,88,50.522,88z" />
      <path d="M36.828,93.446c-0.001,0-0.001,0-0.002,0c-8.234-0.002-11.354-6.207-12.482-9.906c-2.117-6.944-0.607-15.87,3.105-18.365   c3.492-2.348,3.731-3.719,4.063-5.618c0.247-1.415,0.555-3.176,2.361-5.748c3.529-5.026,12.899-5.417,15.687-5.417   c0.596,0,0.952,0.017,0.975,0.018c0-0.001,0.355-0.018,0.951-0.018c2.783,0,12.138,0.391,15.661,5.417   c1.804,2.572,2.11,4.334,2.357,5.749c0.331,1.898,0.57,3.271,4.056,5.617c3.713,2.5,5.218,11.435,3.097,18.384   c-1.166,3.816-4.479,10.292-13.302,9.866c-12.415-0.629-12.452-3.236-12.471-4.638c-0.007-0.499-0.016-0.537-0.36-0.537   c-0.346,0-0.354,0.038-0.362,0.538c-0.021,1.4-0.06,4.008-12.491,4.637C37.383,93.438,37.104,93.446,36.828,93.446z M49.559,48.891   c-2.727,0-11.888,0.375-15.278,5.205c-1.742,2.481-2.026,4.11-2.277,5.547c-0.339,1.945-0.608,3.481-4.276,5.946   c-3.555,2.391-4.968,11.043-2.906,17.806c1.087,3.566,4.092,9.55,12.003,9.552c0.001,0,0.002,0,0.002,0   c0.268,0,0.539-0.007,0.816-0.021c11.964-0.604,12-2.995,12.017-4.145c0.007-0.459,0.015-1.03,0.862-1.03   c0.845,0,0.854,0.571,0.859,1.03c0.017,1.148,0.051,3.54,11.997,4.145c0.277,0.015,0.55,0.021,0.817,0.021   c7.892,0,10.894-5.973,11.98-9.533c2.066-6.768,0.658-15.43-2.897-17.824c-3.662-2.465-3.931-4.001-4.27-5.946   c-0.25-1.437-0.534-3.065-2.274-5.547c-3.385-4.829-12.529-5.205-15.251-5.205c-0.598,0-0.946,0.017-0.95,0.017   C50.507,48.908,50.157,48.891,49.559,48.891z" />
      <ellipse cx="50" cy="27.306" rx="7.833" ry="11.694" />
      <path d="M50,39.25c-4.457,0-8.083-5.358-8.083-11.944S45.543,15.362,50,15.362s8.083,5.358,8.083,11.944S54.457,39.25,50,39.25z    M50,15.862c-4.182,0-7.583,5.134-7.583,11.444S45.818,38.75,50,38.75s7.583-5.134,7.583-11.444S54.182,15.862,50,15.862z" />
      <polyline points="47.986,16.833 50,8.698 51.79,16.833  " />
      <polygon points="48.229,16.893 47.743,16.772 50.014,7.6 52.034,16.779 51.546,16.886 49.986,9.796  " />
      <ellipse
        transform="matrix(0.9478 -0.3189 0.3189 0.9478 -10.2543 10.6704)"
        cx="27.46"
        cy="36.651"
        rx="6.5"
        ry="11.5"
      />
      <path d="M29.905,47.999c-3.332,0-7.05-3.867-8.842-9.195c-2.066-6.141-0.877-12.102,2.65-13.289c0.415-0.14,0.853-0.21,1.302-0.21   c3.332,0,7.05,3.867,8.842,9.195c2.066,6.141,0.877,12.102-2.65,13.289C30.792,47.928,30.354,47.999,29.905,47.999z M25.015,25.804   c-0.395,0-0.779,0.062-1.143,0.184c-3.266,1.099-4.314,6.777-2.336,12.656c1.726,5.13,5.246,8.854,8.369,8.854   c0.395,0,0.779-0.062,1.143-0.184c3.266-1.099,4.314-6.777,2.336-12.656C31.657,29.528,28.138,25.804,25.015,25.804z" />
      <path d="M22.18,29.799c0,0-0.548-8.964,0.875-9.782c0,0,1.322,9.527,2.319,9.451" />
      <path d="M21.93,29.814c-0.093-1.522-0.482-9.162,1-10.014l0.321-0.185l0.051,0.367c0.587,4.229,1.552,8.964,2.089,9.241   l0.002,0.494c-0.007,0-0.015,0-0.022,0c-0.967,0-1.871-4.811-2.484-9.1c-0.648,1.434-0.642,6.158-0.458,9.166L21.93,29.814z" />
      <path d="M22.722,56.137c1.803,4.698,0.809,9.231-2.22,10.124c-3.028,0.894-6.945-2.19-8.748-6.888   c-1.803-4.698-0.809-9.231,2.221-10.125C17.003,48.355,20.919,51.439,22.722,56.137z" />
      <path d="M19.414,66.665c-2.972,0-6.292-3.029-7.893-7.202c-1.155-3.011-1.224-6.065-0.185-8.17   c0.578-1.169,1.466-1.959,2.568-2.284c0.37-0.109,0.759-0.164,1.159-0.164c2.972,0,6.291,3.029,7.893,7.202   c1.155,3.012,1.224,6.065,0.184,8.171c-0.577,1.169-1.465,1.958-2.566,2.283C20.204,66.609,19.814,66.665,19.414,66.665z    M15.063,49.345c-0.352,0-0.694,0.048-1.017,0.144c-0.965,0.285-1.747,0.985-2.261,2.026c-0.979,1.981-0.903,4.886,0.203,7.769   c1.53,3.987,4.653,6.882,7.426,6.882c0.352,0,0.694-0.049,1.018-0.145c0.964-0.284,1.746-0.983,2.26-2.024   c0.979-1.981,0.904-4.887-0.203-7.77C20.958,52.239,17.835,49.345,15.063,49.345z" />
      <path d="M12.539,52.407c0,0-0.487-6.996,0.779-7.634c0,0,1.177,7.435,2.065,7.376" />
      <path d="M12.289,52.425c-0.083-1.196-0.431-7.196,0.917-7.875l0.306-0.154l0.054,0.338c0.517,3.268,1.366,6.939,1.834,7.17l0,0.494   c-0.007,0.001-0.014,0.001-0.02,0.001c-0.869,0.001-1.674-3.71-2.223-7.055c-0.511,1.135-0.54,4.59-0.369,7.045L12.289,52.425z" />
      <path d="M66.913,34.578c-1.979,6.02-0.889,11.828,2.435,12.973s7.622-2.808,9.601-8.826c1.979-6.02,0.887-11.828-2.437-12.973   C73.189,24.607,68.891,28.56,66.913,34.578z" />
      <path d="M70.542,47.998L70.542,47.998c-0.44,0-0.869-0.071-1.275-0.211c-3.447-1.187-4.61-7.148-2.591-13.287   c1.751-5.329,5.386-9.196,8.643-9.196c0.439,0,0.868,0.071,1.274,0.211c3.448,1.188,4.611,7.149,2.593,13.288   C77.435,44.131,73.799,47.998,70.542,47.998z M75.318,25.804c-3.048,0-6.482,3.723-8.168,8.852   c-1.934,5.881-0.912,11.56,2.278,12.658c0.354,0.122,0.729,0.184,1.113,0.184c3.047,0,6.482-3.722,8.169-8.852   c1.934-5.88,0.91-11.559-2.28-12.659C76.076,25.866,75.702,25.804,75.318,25.804z" />
      <path d="M78.088,29.799c0,0,0.534-8.964-0.854-9.782c0,0-1.292,9.527-2.267,9.451" />
      <path d="M78.338,29.813l-0.5-0.029c0.179-2.996,0.186-7.69-0.438-9.147c-0.604,4.32-1.505,9.176-2.453,9.08l0.039-0.498   c0.491-0.287,1.414-4.923,1.999-9.236l0.051-0.373l0.324,0.191C78.808,20.653,78.428,28.292,78.338,29.813z" />
      <path d="M78.396,56.137c-1.641,4.698-0.735,9.231,2.022,10.124c2.756,0.894,6.323-2.19,7.965-6.888   c1.642-4.698,0.736-9.231-2.021-10.124C83.603,48.355,80.037,51.44,78.396,56.137z" />
      <path d="M81.408,66.665L81.408,66.665c-0.368,0-0.728-0.057-1.067-0.166c-0.948-0.308-1.728-1.03-2.254-2.09   c-1.03-2.074-1.003-5.276,0.072-8.354c1.461-4.178,4.493-7.209,7.211-7.209c0.368,0,0.728,0.056,1.068,0.166   c0.948,0.307,1.728,1.03,2.254,2.089c1.03,2.075,1.002,5.276-0.073,8.354C87.159,63.633,84.127,66.665,81.408,66.665z    M85.37,49.345c-2.513,0-5.347,2.891-6.738,6.874c-1.032,2.957-1.07,6.01-0.098,7.968c0.466,0.937,1.144,1.572,1.961,1.836   c0.29,0.095,0.598,0.143,0.913,0.143c2.513,0,5.347-2.892,6.738-6.874c1.033-2.956,1.07-6.009,0.099-7.968   c-0.465-0.937-1.144-1.572-1.961-1.836C85.993,49.393,85.687,49.345,85.37,49.345z" />
      <path d="M87.668,52.407c0,0,0.444-6.996-0.709-7.634c0,0-1.071,7.435-1.88,7.376" />
      <path d="M87.917,52.423l-0.498-0.031c0.152-2.403,0.132-5.762-0.303-6.972c-0.694,4.633-1.379,6.98-2.035,6.98   c-0.007,0-0.014,0-0.021-0.001l0.037-0.498c0.377-0.261,1.147-3.924,1.614-7.163l0.051-0.358l0.317,0.175   C88.31,45.234,87.993,51.229,87.917,52.423z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
BearPaw.propTypes = {
  styling: string,
}

export default BearPaw
