import React from 'react'
import PropTypes from 'prop-types'

function Translate({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <path d="M576 348v228H349.2c2.398-39.602 9.602-80.398 20.398-121.2 10.801-39.602 24-75.602 37.199-106.8zM348 624c1.2 79.199 18 154.8 51.602 228h176.4V624zm276 0v228h169.2c14.398-31.199 26.398-67.199 37.199-106.8C841.2 704.399 847.2 663.598 850.797 624zm176.4-276H624v228h228c-1.2-79.199-18-154.8-51.602-228zM1140 598.8c0 297.6-242.4 538.8-540 538.8-91.199 0-182.4-24-261.6-67.199L96 1138.799c-2.398 1.2-3.602 1.2-6 1.2-6 0-12-2.4-16.801-7.2l-1.2-1.2c-6-6-8.398-15.601-6-24L133.2 870c-48-81.601-73.2-175.2-73.2-271.2C60 302.4 302.4 60 600 60s540 242.4 540 538.8zm-108 1.2c0-13.2-10.801-24-24-24H898.8c-1.2-72-14.398-148.8-46.801-228h60c13.199 0 24-10.802 24-24s-10.801-24-24-24h-81.602c-20.398-40.802-46.801-81.603-79.199-122.4-8.398-10.802-24-12-33.602-3.602-10.801 8.398-12 24-3.602 33.602 24 30 45.602 61.199 62.398 93.602l-152.39-.004v-108c0-13.2-10.8-24-24-24s-24 10.8-24 24v108h-146.4c30-57.602 57.602-92.398 57.602-93.602 8.399-10.801 6-25.2-3.601-33.602-10.801-8.399-25.2-6-33.602 3.601-1.2 1.2-36 46.801-73.2 122.4l-88.8.004c-13.2 0-24 10.801-24 24s10.8 24 24 24H355.2c-25.2 63.602-48 141.6-52.801 228h-110.4c-13.2 0-24 10.801-24 24s10.8 24 24 24h109.2c1.199 72 14.398 148.8 46.8 228h-60c-13.198 0-24 10.801-24 24s10.802 24 24 24h81.603c20.398 40.801 46.8 81.602 79.199 122.4 4.8 6 12 9.602 19.199 9.602 4.8 0 10.8-1.2 14.398-4.801 10.8-8.398 12-24 3.601-33.602-24-30-45.602-61.199-62.398-93.602l152.4.004v108c0 13.199 10.801 24 24 24s24-10.801 24-24V900h146.4c-30 57.602-57.602 92.398-57.602 93.602-8.398 10.8-6 25.199 3.602 33.602 4.8 3.602 9.602 4.8 14.398 4.8 7.2 0 14.398-3.6 19.199-9.6 1.2-1.2 36-46.802 73.199-122.4l88.805-.005c13.199 0 24-10.8 24-24s-10.801-24-24-24h-67.199c25.199-63.602 48-141.6 52.801-228h110.4c13.199 0 24-10.8 24-24z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Translate.propTypes = {
  styling: string,
}

export default Translate
