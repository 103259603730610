import React from 'react'
import PropTypes from 'prop-types'

function Wordsy({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Word Game</title>
      <path d="m262.07 761.62h146.93v-146.93h-146.93zm50.16-29.387 67.379-67.379v67.379zm46.602-88.156-67.379 67.379v-67.379zm-96.762-58.77h146.93v-146.93h-146.93zm29.383-117.54h88.156v88.156h-88.156zm-29.383-58.77h146.93v-146.93h-146.93zm29.383-117.54h88.156v88.156h-88.156zm146.93 293.85h146.93v-146.93h-146.93zm50.164-29.383 67.379-67.379v67.379zm46.602-88.156-67.379 67.379v-67.379zm-96.766-58.77h146.93v-146.93h-146.93zm29.387-117.54h88.156v88.156h-88.156zm146.92 293.85h146.93v-146.93h-146.93zm50.164-29.383 67.379-67.379v67.379zm46.602-88.156-67.379 67.379v-67.379zm-96.766-58.77h146.93v-146.93h-146.93zm29.387-117.54h88.156v88.156h-88.156zm146.93-29.383v146.93h146.93v-146.93zm96.766 29.383-67.379 67.379v-67.379zm-46.605 88.156 67.379-67.379v67.379zm-402.78 235.08h146.93v146.93h-146.93zm176.31 0h146.93v146.93h-146.93zm176.31 0h146.93v146.93h-146.93zm-528.93 176.31h146.93v146.93h-146.93zm176.31 0h146.93v146.93h-146.93zm176.31 0h146.93v146.93h-146.93zm176.31 0h146.93v146.93h-146.93zm0-352.62h146.93v146.93h-146.93z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Wordsy.propTypes = {
  styling: string,
}

export default Wordsy
