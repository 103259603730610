import React from 'react'
import PropTypes from 'prop-types'
/**
 * @summary Alphabet
 * @component
 *
 * @param {object} props
 *
 * @returns {node} jsx markup
 */
function Alphabet({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={styling}
    >
      <title>Alphabet</title>
      <g fillRule="evenodd">
        <path d="M6.202 10.257h-3.39l-.34 1.02h.34c.336 0 .575.077.718.232.143.156.214.358.214.609a.85.85 0 01-.214.6c-.143.154-.382.232-.718.232H.932c-.335 0-.575-.078-.718-.233C.071 12.562 0 12.36 0 12.11c0-.25.075-.454.225-.612.15-.158.397-.231.742-.22L3.159 4.73h-.912c-.336 0-.576-.077-.719-.232-.143-.155-.214-.358-.214-.608s.071-.453.214-.608c.143-.155.383-.233.719-.233l3.015.008 2.778 8.219c.327 0 .542.043.647.13a.89.89 0 01.313.71c0 .245-.07.445-.211.6-.14.155-.379.233-.715.233H6.195c-.336 0-.575-.078-.718-.233-.143-.155-.215-.357-.215-.608a.85.85 0 01.215-.6c.143-.155.382-.232.718-.232h.34l-.333-1.02zm-.579-1.673L4.5 5.27 3.37 8.584h2.253zM13.572 12.68V12.3c-.33.217-.695.38-1.095.488-.399.109-.762.163-1.088.163-.708 0-1.284-.232-1.726-.695C9.221 11.792 9 11.28 9 10.72c0-.683.282-1.316.847-1.901.564-.585 1.345-.878 2.34-.878.4 0 .861.053 1.385.16v-.39a.766.766 0 00-.254-.595c-.17-.154-.493-.23-.97-.23-.39 0-.897.095-1.52.285a2.053 2.053 0 01-.54.104.551.551 0 01-.448-.235c-.122-.156-.183-.356-.183-.599 0-.138.021-.257.064-.357a.602.602 0 01.18-.242c.078-.061.239-.134.484-.219.326-.11.659-.2.998-.266.339-.066.646-.099.92-.099.82 0 1.457.218 1.91.655.453.437.68 1.033.68 1.79v3.35h.225c.317 0 .544.076.68.227.134.15.202.348.202.591a.841.841 0 01-.203.584c-.135.15-.362.226-.68.226h-1.545zm0-2.914a6.283 6.283 0 00-1.462-.19c-.536 0-.998.161-1.384.484-.24.207-.36.416-.36.627a.46.46 0 00.173.374c.215.174.509.261.882.261.318 0 .678-.076 1.079-.23a3.69 3.69 0 001.072-.627v-.699z" />
      </g>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Alphabet.propTypes = {
  styling: string,
}

export default Alphabet
