export const languageColors = {
  dtd: 'rgb(255,112,255)',
  hei: 'rgb(255,128,255)',
  kwk: 'rgb(255,176,255)',
  nuk: 'rgb(255,96,176)',
  'hei-wui': 'rgb(255,0,138)',
  has: 'rgb(255,0,255)',
  coo: 'rgb(255,216,96)',
  hur: 'rgb(255,168,80)',
  thp: 'rgb(192,48,0)',
  oka: 'rgb(255,96,96)',
  blc: 'rgb(255,244,208)',
  shs: 'rgb(224,0,0)',
  str: 'rgb(255,128,0)',
  sec: 'rgb(255,88,32)',
  squ: 'rgb(255,192,0)',
  lil: 'rgb(224,56,0)',
  git: 'rgb(208,156,0)',
  ncg: 'rgb(144,108,0)',
  'tsi-sou': 'rgb(0.5,0.5,0.5)',
  'crx, caf': 'rgb(0,208,104)',
  bea: 'rgb(144,240,0)',
  kkz: 'rgb(0,208,0)',
  den: 'rgb(192,255,96)',
  tli: 'rgb(255,255,144)',
  bcr: 'rgb(0,244,112)',
  tce: 'rgb(0,160,0)',
  tht: 'rgb(0,224,0)',
  sek: 'rgb(80,255,80)',
  clc: 'rgb(112,255,112)',
  kut: 'rgb(0,0,255)',
  oji: 'rgb(0.5,0.5,0.5)',
  crk: 'rgb(176,176,176)',
  hai: 'rgb(160,0,160)',
  tsi: 'rgb(192,144,0)',
}
