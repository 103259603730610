import React from 'react'
import PropTypes from 'prop-types'

function ImagesNone({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <g>
        <path d="M884.16 565.08c0-30.238-24.48-54.719-54.719-54.719-30.238 0-54.719 24.48-54.719 54.719s24.48 54.719 54.719 54.719c30.117.004 54.719-24.477 54.719-54.719zM301.92 807.12l38.879-38.879-135-369.96 528.72-192.24 44.879 123.6 38.879-38.879-45.355-125.16c-2.398-6.238-6.84-11.281-12.84-14.039-6-2.879-12.84-3.121-19.078-.84l-575.64 209.4c-6.238 2.16-11.281 6.84-14.16 12.84-2.64 6-3.121 12.84-.84 19.078z" />
        <path d="M649.08 642.12h-.238l-298.32 298.32 9.96 27.121c3.602 10.199 13.079 16.441 23.282 16.441 1.8 0 3.601-.48 5.28-.84v43.078c0 14.04 10.68 24.602 24.603 24.602h612.12c14.039 0 25.078-10.68 25.078-24.602L1050.84 414c0-14.039-11.04-25.32-25.078-25.32h-123.6l-49.922 49.922h148.92v378.84l-36-53.879c-13.32-18.602-34.68-30.359-57.72-30.359-23.28 0-44.64 11.762-57.96 31.078L814.8 815.52 692.76 666.6c-10.684-13.32-27.961-22.44-43.684-24.48zM389.16 414v305.88l49.922-49.922-.004-231.36h231.24l49.922-49.922H413.76c-13.922 0-24.602 11.281-24.602 25.32zM1011.5 141.36l-870.12 870.12c-12.961 12.961-12.961 34.078 0 47.039 6.48 6.48 15 9.719 23.52 9.719s17.039-3.238 23.52-9.719l870.12-870.12c12.961-12.961 12.961-34.078 0-47.039-12.961-12.961-33.961-12.961-47.039 0z" />
      </g>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
ImagesNone.propTypes = {
  styling: string,
}

export default ImagesNone
