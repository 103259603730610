import React from 'react'
import PropTypes from 'prop-types'

function Upload({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <path d="M1122 1183.2H78c-31.199 0-57.602-24-57.602-55.199v-528c0-30 25.199-55.199 57.602-55.199 32.398 0 57.602 24 57.602 55.199v474h930v-474c0-30 25.199-55.199 57.602-55.199 32.398 0 57.602 24 57.602 55.199v528c-2.406 30-27.605 55.199-58.805 55.199z" />
      <path d="M864 255.6l-222-222c-21.602-21.602-57.602-21.602-80.398 0l-224.4 222c-10.801 10.801-16.801 25.199-16.801 40.801s6 28.801 16.801 39.602c10.801 10.801 25.199 16.801 39.602 16.801 15.602 0 30-6 40.801-16.801l122.4-121.2v570c0 33.602 26.398 60 60 60s60-26.398 60-60v-572.4l123.6 123.6c21.602 21.602 57.602 21.602 80.398 0 10.801-10.801 16.801-25.199 16.801-39.602 0-15.598-6-30-16.801-40.797z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Upload.propTypes = {
  styling: string,
}

export default Upload
