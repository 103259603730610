import React from 'react'
import PropTypes from 'prop-types'

function MicrophoneOff({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <g>
        <path d="M621 773.04c109.92 0 198.96-89.039 198.96-198.96V335.76l184.08-184.08c14.641-14.641 14.641-38.398 0-53.039C989.759 84.36 966.841 84 952.2 97.563c-.36.36-.719.84-1.078 1.2l-755.04 755.16s-.719.718-1.2 1.077c-13.558 14.762-13.198 37.56 1.079 51.84 7.32 7.32 16.922 11.04 26.52 11.04 9.601 0 19.199-3.602 26.52-11.04L357.84 798c55.199 66.602 135.24 112.08 225.72 121.92V1053H445.8c-20.762 0-37.56 16.801-37.56 37.56 0 20.761 16.802 37.558 37.56 37.558h356.52c20.762 0 37.559-16.8 37.559-37.559 0-20.762-16.801-37.559-37.56-37.559H664.56V919.92C835.44 901.201 968.76 756 968.76 580.2v-32.16c0-20.762-16.8-37.559-37.558-37.559-20.762 0-37.56 16.801-37.56 37.56v32.16c0 147-119.64 266.76-266.76 266.76h-5.878c-85.078 0-161.04-40.079-209.88-102.24l50.879-50.88c36.359 48.121 93.96 79.32 159 79.32zM621 72c-109.92 0-198.96 89.039-198.96 198.96v250.8l363.12-363.12c-35.879-52.32-96-86.641-164.16-86.641zM354.48 589.32c0-3-.121-6.121-.121-9.121v-32.16c0-20.762-16.801-37.56-37.56-37.56-20.761 0-37.558 16.802-37.558 37.56v32.16c0 26.039 3 51.359 8.52 75.84z" />
      </g>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
MicrophoneOff.propTypes = {
  styling: string,
}

export default MicrophoneOff
