import React from 'react'
import PropTypes from 'prop-types'

function PC({ styling }) {
  return (
    <svg
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
      className={styling}
    >
      <path d="M3.37197 16.25H9.98833L9.69659 18.3334H7.64529C7.17304 18.3334 6.79058 18.7062 6.79058 19.1667C6.79058 19.6271 7.17304 20 7.64529 20H17.0471C17.5194 20 17.9018 19.6271 17.9018 19.1667C17.9018 18.7062 17.5194 18.3334 17.0471 18.3334H14.9958L14.7041 16.25H21.3204C22.7349 16.25 23.8845 15.1292 23.8845 13.7501V2.49997C23.8845 1.12088 22.7349 0 21.3204 0H3.37175C1.95726 0 0.807617 1.12088 0.807617 2.49997V13.7501C0.807617 15.1292 1.95726 16.25 3.37175 16.25H3.37197ZM13.2704 18.3334H11.4222L11.7139 16.25H12.98L13.2718 18.3334H13.2706H13.2704ZM21.3209 14.5836H3.37197C2.90086 14.5836 2.51726 14.2096 2.51726 13.7503V13.3336H22.1756V13.7503C22.1756 14.2096 21.792 14.5836 21.3209 14.5836ZM3.37197 1.66687H21.3209C21.792 1.66687 22.1756 2.04087 22.1756 2.50019V11.6668H2.51726V2.50019C2.51726 2.04087 2.90086 1.66687 3.37197 1.66687Z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
PC.propTypes = {
  styling: string,
}

export default PC
