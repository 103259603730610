import React from 'react'
import PropTypes from 'prop-types'

function UnorderedList({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <g>
        <path d="M240 180c0 66.273-53.727 120-120 120S0 246.273 0 180 53.727 60 120 60s120 53.727 120 120M240 600c0 66.273-53.727 120-120 120S0 666.273 0 600s53.727-120 120-120 120 53.727 120 120M240 1020c0 66.273-53.727 120-120 120S0 1086.273 0 1020s53.727-120 120-120 120 53.727 120 120M480 240h660a60.002 60.002 0 0051.961-90A60.002 60.002 0 001140 120H480a60.002 60.002 0 00-51.961 90A60.002 60.002 0 00480 240zM1140 540H480a60.002 60.002 0 00-51.961 90A60.002 60.002 0 00480 660h660a60.002 60.002 0 0051.961-90A60.002 60.002 0 001140 540zM1140 960H480a60.002 60.002 0 00-51.961 90A60.002 60.002 0 00480 1080h660a60.002 60.002 0 0051.961-90A60.002 60.002 0 001140 960z" />
      </g>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
UnorderedList.propTypes = {
  styling: string,
}

export default UnorderedList
