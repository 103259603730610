import React from 'react'
import PropTypes from 'prop-types'

function TimesCircleSolid({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Error</title>
      <path d="M989 211A549.866 549.866 0 00759.75 73.66a550.006 550.006 0 00-508.49 101.192 550.003 550.003 0 00-198.434 478.99 550.032 550.032 0 0089.992 251.63 550.033 550.033 0 00197.97 179.51 550.026 550.026 0 00259.21 65.012c145.82.18 285.73-57.551 389-160.5a550.126 550.126 0 00119.48-178.55 550.03 550.03 0 000-421.4 550.026 550.026 0 00-119.48-178.55zM812 741.5c9.316 9.371 14.539 22.039 14.539 35.25S821.316 802.629 812 812c-9.363 9.316-22.039 14.539-35.25 14.539S750.871 821.316 741.5 812L600 670.5 458.5 812a49.978 49.978 0 01-35.25 14.539A49.986 49.986 0 01388 812a50.002 50.002 0 010-70.5L529.5 600 388 458.5a50.01 50.01 0 012.621-67.879A50.007 50.007 0 01458.5 388L600 529.5 741.5 388a50.091 50.091 0 0116.199-12.488 50.036 50.036 0 0119.852-4.953 49.954 49.954 0 0120.164 3.41 49.926 49.926 0 0117.121 11.2 50.074 50.074 0 0111.199 17.116 50.092 50.092 0 013.406 20.168 49.94 49.94 0 01-4.957 19.844A50.003 50.003 0 01812 458.5L670.5 600z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
TimesCircleSolid.propTypes = {
  styling: string,
}

export default TimesCircleSolid
