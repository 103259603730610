import React from 'react'
import PropTypes from 'prop-types'

function OrderedList({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <g>
        <path d="M137.5 637.5h25c23.207 0 45.461-9.219 61.871-25.629S250 573.207 250 550v-12.5c0-23.207-9.219-45.461-25.629-61.871S185.707 450 162.5 450h-75a37.498 37.498 0 100 75h75a12.5 12.5 0 0112.5 12.5V550a12.5 12.5 0 01-12.5 12.5h-25c-23.207 0-45.461 9.219-61.871 25.629S50 626.793 50 650v62.5a37.49 37.49 0 0010.984 26.516A37.485 37.485 0 0087.5 750h125a37.498 37.498 0 100-75H125v-25c0-6.902 5.598-12.5 12.5-12.5zM162.5 1075h-75a37.498 37.498 0 100 75h75c23.207 0 45.461-9.219 61.871-25.629S250 1085.707 250 1062.5V1050a86.996 86.996 0 00-16-50 87.002 87.002 0 0016-50v-12.5c0-23.207-9.219-45.461-25.629-61.871S185.707 850 162.5 850h-75a37.498 37.498 0 100 75h75a12.5 12.5 0 0112.5 12.5V950a12.5 12.5 0 01-12.5 12.5h-50a37.498 37.498 0 100 75h50A12.5 12.5 0 01175 1050v12.5a12.5 12.5 0 01-12.5 12.5zM162.5 350a37.49 37.49 0 0026.516-10.984A37.485 37.485 0 00200 312.5v-225a37.49 37.49 0 00-10.984-26.516A37.485 37.485 0 00162.5 50h-75a37.498 37.498 0 100 75H125v187.5a37.49 37.49 0 0010.984 26.516A37.485 37.485 0 00162.5 350zM1150 150H400a50 50 0 000 100h750a50 50 0 000-100zM1150 550H400a50 50 0 000 100h750a50 50 0 000-100zM1150 950H400a50 50 0 000 100h750a50 50 0 000-100z" />
      </g>
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
OrderedList.propTypes = {
  styling: string,
}

export default OrderedList
