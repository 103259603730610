import React from 'react'
import PropTypes from 'prop-types'

function Preview({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Preview</title>
      <path d="M75 325a24.987 24.987 0 0017.676-7.324A24.986 24.986 0 00100 300V175h125c8.93 0 17.184-4.766 21.652-12.5a25.005 25.005 0 000-25A25.01 25.01 0 00225 125H75a24.987 24.987 0 00-17.676 7.324A24.986 24.986 0 0050 150v150a24.987 24.987 0 007.324 17.676A24.986 24.986 0 0075 325zM1125 125H975a25.009 25.009 0 00-21.652 12.5 25.005 25.005 0 000 25A25.01 25.01 0 00975 175h125v125c0 8.93 4.766 17.184 12.5 21.652a25.005 25.005 0 0025 0A25.01 25.01 0 001150 300V150a24.987 24.987 0 00-7.324-17.676A24.986 24.986 0 001125 125zM225 1025H100V900c0-8.93-4.766-17.184-12.5-21.652a25.005 25.005 0 00-25 0A25.01 25.01 0 0050 900v150a24.987 24.987 0 007.324 17.676A24.986 24.986 0 0075 1075h150c8.93 0 17.184-4.765 21.652-12.5a25.005 25.005 0 000-25A25.01 25.01 0 00225 1025zM1125 875a24.987 24.987 0 00-17.676 7.324A24.986 24.986 0 001100 900v125H975a25.009 25.009 0 00-21.652 12.5 25.005 25.005 0 000 25A25.01 25.01 0 00975 1075h150a24.987 24.987 0 0017.676-7.324A24.986 24.986 0 001150 1050V900a24.987 24.987 0 00-7.324-17.676A24.986 24.986 0 001125 875zM775 600c0 96.648-78.352 175-175 175s-175-78.352-175-175 78.352-175 175-175 175 78.352 175 175" />
      <path d="M1097.8 610.5a25.004 25.004 0 000-21c-6.75-14.5-169-353.75-497.75-353.75S109.05 575 102.3 589.5a25.004 25.004 0 000 21c6.75 14.5 169 353.75 497.75 353.75s491-339.25 497.75-353.75zM600.05 825a225.004 225.004 0 01-225.002-225.002A225.004 225.004 0 01600.05 374.996a225.004 225.004 0 01225.002 225.002A225.004 225.004 0 01600.05 825z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Preview.propTypes = {
  styling: string,
}

export default Preview
