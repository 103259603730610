import React from 'react'
import PropTypes from 'prop-types'

function Trash({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Delete</title>
      <path
        d="M854.95 1200H345.04c-41.691 0-81.34-46.742-88.539-104.43l-93.023-782.52h873.03l-93.023 782.52c-7.195 57.676-46.848 104.43-88.539 104.43zm-281.29-143.48c0 14.406 11.789 26.086 26.332 26.086s26.332-11.68 26.332-26.086v-600c0-14.41-11.789-26.09-26.332-26.09s-26.332 11.68-26.332 26.09zm185.23-5.418c-1.773 14.301 8.492 27.316 22.926 29.074 14.434 1.754 27.574-8.414 29.348-22.715l73.812-595.53c1.77-14.301-8.496-27.316-22.93-29.07-14.434-1.758-27.574 8.41-29.348 22.711zm-370.08 6.36c1.773 14.3 14.914 24.468 29.348 22.714 14.434-1.757 24.699-14.773 22.926-29.074l-73.809-595.53c-1.773-14.3-14.914-24.469-29.348-22.71-14.434 1.753-24.699 14.77-22.93 29.07zm-48.867-900.94h-214.03c-29.117 0-52.598 23.354-52.598 52.171 0 28.758 23.551 52.176 52.598 52.176h948.14c29.117 0 52.598-23.359 52.598-52.176 0-28.758-23.551-52.172-52.598-52.172h-214.03v-52.293c0-57.629-47.23-104.23-105.41-104.23h-309.27c-58.27 0-105.41 46.598-105.41 104.23zm105.34 0h309.42c0-18.11-.004-52.177-.074-52.177 0 0-309.34-.07-309.34-.117z"
        fillRule="evenodd"
      />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Trash.propTypes = {
  styling: string,
}

export default Trash
