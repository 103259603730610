import React from 'react'
import PropTypes from 'prop-types'

function Tablet({ styling }) {
  return (
    <svg
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
      className={styling}
    >
      <path d="M13.9855 0H2.24241C1.23908 0 0.422852 0.821579 0.422852 1.82807V18.1719C0.422852 19.1786 1.23926 20 2.24241 20H13.9879C14.9912 20 15.8075 19.1784 15.8075 18.1719V1.82807C15.805 0.821403 14.9886 0 13.9855 0ZM8.62994 18.6158H7.60038C7.32585 18.6158 7.10361 18.393 7.10361 18.1198C7.10361 17.8467 7.32568 17.6189 7.60038 17.6189H8.62994C8.90446 17.6189 9.12671 17.8418 9.12671 18.1198C9.12671 18.3979 8.90464 18.6158 8.62994 18.6158ZM14.3878 16.7039C14.3878 16.9768 14.1657 17.1998 13.891 17.1998L2.33893 17.2007C2.06441 17.2007 1.84217 16.9779 1.84217 16.7047V2.10596C1.84217 1.82807 2.06423 1.60509 2.33893 1.60509H13.8924C14.167 1.60509 14.3892 1.82789 14.3892 2.10596V16.7039H14.3878Z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Tablet.propTypes = {
  styling: string,
}

export default Tablet
